// UserManagement pages handler
import React from "react";
import qs from "qs";
import EmailRecovery from "./EmailRecovery";
import EmailVerification from "./EmailVerification";
import PasswordReset from "./PasswordReset";
import NotFound from "../404";

const UserManagement = props => {
    let { mode, oobCode } = qs.parse(props.location.search, {
        ignoreQueryPrefix: true
    });

    if (oobCode) {
        switch (mode) {
            case "resetPassword":
                return <PasswordReset oobCode={oobCode} />;
            case "recoverEmail":
                return <EmailRecovery oobCode={oobCode} />;
            case "verifyEmail":
                return <EmailVerification oobCode={oobCode} />;
            default:
                return <NotFound />;
        }
    }
    return <NotFound />;
};

export default UserManagement;
